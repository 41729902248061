const deepObjectMap = ( value, fnKey, fnValue ) =>
{
    const objectValueType = Object.prototype.toString.call( value );

    if ( objectValueType.indexOf('Array') >= 0 )
    {
        const mappedArray = [];
        value.forEach( item =>
        {
            mappedArray.push( deepObjectMap( item, fnKey, fnValue ) )
        }
        );
        return mappedArray;
    }

    if ( objectValueType.indexOf('Object') >= 0 )
    {
        const mappedObject = {};
        Object.keys( value ).forEach( key =>
        {
            mappedObject[ fnKey( key ) ] = deepObjectMap( value[ key ], fnKey, fnValue );
        }
        );
        return mappedObject;    
    }

    return fnValue( value );

}

export default deepObjectMap;