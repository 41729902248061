import React from 'react';
import { getMinRequiredMetas } from '../Constants';
import addRequiredMetas from './addRequiredMetas';
import maybeAddSlash from './maybeAddSlash';

const prepHelmetData = (nodeObs) => 
{
    const helmetProps = {};
    const minRequiredMetas = getMinRequiredMetas(nodeObs);

    helmetProps.title = minRequiredMetas.title?.textContent;
    const enrichedMetas = addRequiredMetas(nodeObs, minRequiredMetas);
    helmetProps.meta = enrichedMetas
        .filter((meta) => meta.tagName === 'META')
        .map((meta) => meta.attributes);

    const canonical = nodeObs.find(
        (nodeOb) =>
            nodeOb.tagName === 'LINK' && nodeOb.attributes.rel === 'canonical'
    );
    if (canonical) 
    {
        canonical.attributes.href = maybeAddSlash(canonical.attributes.href);
        helmetProps.link = [{ ...canonical.attributes }];
    }

    const schemaJSON = nodeObs.find(
        (nodeOb) =>
            nodeOb.tagName === 'SCRIPT' &&
            nodeOb.attributes.type === 'application/ld+json'
    );
    if (schemaJSON?.textContent) 
    {
        helmetProps.children = (
            <script type="application/ld+json">{schemaJSON.textContent}</script>
        );
    }
    return helmetProps;
};

export default prepHelmetData;
