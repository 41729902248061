const addRequiredMetas = (nodeObs, minRequiredMetas) => 
{
    Object.keys(minRequiredMetas).forEach((key) => 
    {
        const reqMeta = minRequiredMetas[key];
        if (reqMeta.tagName !== 'META') return;
        let metaProp;
        let metaCompareValue = true;
        if (undefined !== reqMeta.attributes.property) 
        {
            metaProp = 'property';
        }
        else if (undefined !== reqMeta.attributes.name) 
        {
            metaProp = 'name';
        }
        else 
        {
            metaProp = [...Object.keys(reqMeta.attributes)].shift();
            metaCompareValue = false;
        }

        if (
            metaCompareValue &&
            !nodeObs.some(
                (nodeOb) =>
                    reqMeta.attributes[metaProp] ===
                    nodeOb?.attributes?.[metaProp]
            )
        ) 
        {
            nodeObs.push(reqMeta);
        }
        else if (
            !metaCompareValue &&
            !nodeObs.some(
                (nodeOb) => undefined !== nodeOb?.attributes?.[metaProp]
            )
        ) 
        {
            nodeObs.push(reqMeta);
        }
    });
    return nodeObs;
};
export default addRequiredMetas;
