const maybeAddSlash = (urlString) => 
{
    const urlOb = new URL(urlString);
    const path = urlOb.pathname;
    if (path.charAt(path.length - 1) === '/') return urlString;
    const lastPath = path.split('/').pop();
    if (lastPath.indexOf('.') >= 0) return urlString;
    urlOb.pathname += '/';
    return urlOb.toString();
};

export default maybeAddSlash;
