/* eslint-disable no-underscore-dangle,no-constructor-return */

class CurrentLocation 
{
    static _instance = null;
    
    constructor( urlInput )
    {
        if ( CurrentLocation._instance !== null ) return CurrentLocation._instance;
        this._url = urlInput;
        CurrentLocation._instance = this;
    }

    get url()
    {
        return this._url;
    }

    set url( urlInput )
    {
        this._url = urlInput;
    }
}

let currLocation;

function useLocation( urlInput=null )
{
    if ( urlInput !== null )
    {
        if ( typeof currLocation === 'undefined' ) 
        {
            currLocation = new CurrentLocation(urlInput );
        }
        else 
        {
            currLocation.url = urlInput 
        }
    }
    return currLocation.url;
}

export default useLocation;