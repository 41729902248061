import { parseHeadObject } from '../../../../utilities/sanitizeHeader';
import prepHelmetData from '../../../../components/simple/seo/prepHelmetData';

const processHeadArgs = (metadata, siteMetadata, pageContext={}) => 
{
    try 
    {
        const parsedHeadNodes = parseHeadObject(metadata, siteMetadata.siteUrl, pageContext);
        return prepHelmetData(parsedHeadNodes);
    }
    catch (err) 
    {
        // eslint-disable-next-line no-console
        console.error(err);
        return null;
    }
};
export default processHeadArgs;
