const dynamicSort = (order) => 
{
    const sortOrder = order === 'desc' ? -1 : 1;

    const arrayOrder = (a, b) => 
    {
        let result;
        const objFrom = {
            dateUpdate: a.node?.cdmfields?.eat?.post_updated_date || '',
            dateCreation: a.node.date,
        };
        const objTo = {
            dateUpdate: b.node?.cdmfields?.eat?.post_updated_date || '',
            dateCreation: b.node.date,
        };

        const date1 =
            objFrom.dateUpdate !== ''
                ? new Date(objFrom.dateUpdate)
                : new Date(objFrom.dateCreation);
        const date2 =
            objTo.dateUpdate !== ''
                ? new Date(objTo.dateUpdate)
                : new Date(objTo.dateCreation);
        if (date1 < date2) result = -1;
        else if (date1 > date2) result = 1;
        else result = 0;
        return result * sortOrder;
    };
    return arrayOrder;
};
const orderByPostDate = (array, order) => array.sort(dynamicSort(order));
// order can be desc or asc

export default orderByPostDate;
