import DOMPurify from 'isomorphic-dompurify';
import orderByPostDate from '../../orderBy/orderByPostDate';

const doSanitize = (el, shouldSanitize) =>
    shouldSanitize ? DOMPurify.sanitize(el) : el;

export const reducePostEdges = (postEdges, shouldSanitize = true) =>
    postEdges.reduce((postObs, postEdge) => 
    {
        const {
            author,
            cdmfields,
            title,
            uri,
            databaseId,
            featuredImage,
            pinned,
        } = postEdge.node;
        return [
            ...postObs,
            {
                author: doSanitize(
                    author?.node?.cdmfields.authorDisplay.displayname,
                    shouldSanitize
                ),
                readingtime: doSanitize(
                    cdmfields.readingtime.minutes,
                    shouldSanitize
                ),
                link: doSanitize(uri, shouldSanitize),
                title: doSanitize(title, shouldSanitize),
                featuredImage,
                databaseId: parseInt(databaseId, 10),
                pinned,
            },
        ];
    }, []);
const addPinnedMap = (postEdge) => ({
    ...postEdge,
    node: {
        ...postEdge.node,
        pinned: true,
    },
});

const filterAndSortRelatedPosts = (
    postId,
    relatedPosts,
    pinnedPosts,
    shouldSanitize = true
) => 
{
    if (!relatedPosts?.length) 
    {
        if (pinnedPosts?.length)
            return reducePostEdges(
                pinnedPosts.map(addPinnedMap),
                shouldSanitize
            );
        return [];
    }
    const filteredRelated = relatedPosts
        .filter(
            (postEdge) =>
                postEdge?.node?.databaseId !== postId &&
                !pinnedPosts.some(
                    (pPostEdge) =>
                        pPostEdge.node.databaseId === postEdge.node.databaseId
                )
        )
        .filter(
            (item) =>
                item.node?.uri && !item.node?.uri.includes('/uncategorized/')
        );

    return reducePostEdges(
        [
            ...pinnedPosts.map(addPinnedMap),
            ...orderByPostDate(filteredRelated, 'desc'),
        ],
        shouldSanitize
    );
};
export default filterAndSortRelatedPosts;
