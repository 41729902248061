// eslint-disable-next-line import/no-extraneous-dependencies
import { parse as nodeParse } from 'node-html-parser';
import DOMPurify from 'isomorphic-dompurify';

import deepObjectMap from '../deepObjectMap';
import addTrailingSlash from '../addTrailingSlash';
import { build } from '../../Constants.json';

const maybeQualifyURL = (value, baseUrl) => 
{
    if (value[0] !== '/') return value;
    const url = new URL(value, baseUrl);
    return url?.href;
};

const sanitizeMeta = ({ tagName, attributes, textContent }, baseUrl) => 
{
    const nodeData = { tagName, attributes: {} };
    Object.keys(attributes).forEach((key) => 
    {
        const attrValue = DOMPurify.sanitize(attributes[key]);

        nodeData.attributes[key] =
            key === 'content' || key === 'href'
                ? maybeQualifyURL(attrValue, baseUrl)
                : attrValue;
    });
    if (tagName === 'TITLE') 
    {
        nodeData.textContent = DOMPurify.sanitize(textContent);
    }
    return nodeData;
};


export const filterSchemaJSON = ( contentJSON ) =>
{
    if ( !Array.isArray( contentJSON['@graph'] ) ) return contentJSON;
    const json = { ...contentJSON }
    json['@graph'] = contentJSON['@graph'].filter( graphOb => ( typeof graphOb['@type'] !== 'string' || graphOb['@type']?.toLowerCase() !== 'breadcrumblist') );
    return json;
}

export const sanitizeJsonString = value => ( typeof value === 'string' ) ? DOMPurify.sanitize( value ) : value;

const sanitizeScript = ({ tagName, attributes, textContent }) => 
{
    if (!attributes.type || attributes.type !== 'application/ld+json')
        return null;

    try 
    {
        const contentJSON = deepObjectMap( JSON.parse( decodeURIComponent(textContent ) ), sanitizeJsonString, sanitizeJsonString );
        if ( !contentJSON ) return null;
        const contentJSONFiltered = filterSchemaJSON( contentJSON );
        const content = JSON.stringify( contentJSONFiltered );
        return {
            tagName,
            attributes,
            textContent: content,
        };
    }
    catch (err) 
    {
        return null;
    }
};
/**
 *
 * @param {*} headHTML
 * @param {*} baseUrl
 * @returns
 */
const parseHeadElements = (headHTML, baseUrl) => 
{
    const registry = [];

    const headNodes = nodeParse(headHTML);
    if (!headNodes?.childNodes?.length) return registry;
    const tags = headNodes.childNodes?.filter((node) => node.nodeType === 1);

    if (!Array.isArray(tags) || !tags.length) return registry;

    tags.forEach((el) => 
    {
        const { tagName } = el;
        let nodeData = { tagName };
        switch (tagName.toUpperCase()) 
        {
            case 'SCRIPT': {
                nodeData = sanitizeScript(el);
                break;
            }
            case 'LINK': {
                nodeData = false;
                break;
            }
            default: {
                nodeData = sanitizeMeta(el, baseUrl);
                break;
            }
        }
        if (nodeData) registry.push(nodeData);
    });
    return registry;
};

const parseRobotsElements = (robots) => 
{
    const parseRobots = Object.values(robots);
    const purifyString = JSON.stringify(parseRobots).replace(/[\[\]"]+/g, '');

    return [
        {
            tagName: 'META',
            attributes: {
                name: 'robots',
                content: purifyString,
            },
        },
    ];
};

export const addPageToCanonical = ( url, currentPage ) =>
{
    if ( !currentPage || currentPage < 2 ) return url;
    return `${ addTrailingSlash(url) }${build.defaults.PAGE_PATHNAME}/${currentPage}`
}

export const parseHeadObject = (headMetaObject, baseUrl, pageContext={}) => 
{
    const headMarkup = parseHeadElements(headMetaObject.headMarkup, baseUrl);
    const canonical = headMetaObject.canonical
        ? [
              {
                  tagName: 'LINK',
                  attributes: {
                      rel: 'canonical',
                      href: addPageToCanonical(
                        maybeQualifyURL(headMetaObject.canonical, baseUrl),
                        pageContext?.currentPage || 0
                      )
                  },
              },
          ]
        : [];
    const robots = headMetaObject.robots
        ? parseRobotsElements(JSON.parse(headMetaObject.robots))
        : [];
    return [...headMarkup, ...canonical, ...robots];
};

export default parseHeadElements;
